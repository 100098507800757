<template>
  <div
    class="flex flex-row justify-center item-center w-44 bg-gray-700 h-7 rounded"
  >
    <p class="flex flex-row text-white font-medium text-sm mt-1.5">35%</p>
    <span
      class="flex flex-row bg-yellow-200 w-28 h-1.5 mt-3 ml-1.5 rounded-3xl"
    >
      <span class="w-10	bg-yellow-500 h-full rounded-3xl"></span>
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  setup() {
    return {}
  }
})
</script>
