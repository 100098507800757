<template>
  <div class="flex min-h-screen">
    <add-account-side-bar :activeIndex="1" />
    <business-add-account />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AddAccountSideBar from '@/components/AddAccountSideBar.vue'
import BusinessAddAccount from '@/components/BusinessAddAccount.vue'

export default defineComponent({
  components: {
    AddAccountSideBar,
    BusinessAddAccount
  },
  setup() {
    return {}
  }
})
</script>
