
import { defineComponent } from 'vue'
import AddAccountSideBar from '@/components/AddAccountSideBar.vue'
import BusinessAddAccount from '@/components/BusinessAddAccount.vue'

export default defineComponent({
  components: {
    AddAccountSideBar,
    BusinessAddAccount
  },
  setup() {
    return {}
  }
})
