
import { defineComponent } from 'vue'
import AddAccountSideBar from '@/components/AddAccountSideBar.vue'
import ConversationsDemo from '@/components/ConversationsDemo.vue'

export default defineComponent({
  components: {
    AddAccountSideBar,
    ConversationsDemo
  },
  setup() {
    return {}
  }
})
