<template>
  <div class="flex min-h-screen">
    <progress-side-bar :activeIndex="2" />
    <business-password-form />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ProgressSideBar from '@/components/ProgressSideBar.vue'
import BusinessPasswordForm from '@/components/BusinessPasswordForm.vue'

export default defineComponent({
  components: {
    ProgressSideBar,
    BusinessPasswordForm
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
