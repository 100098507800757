
import { defineComponent } from 'vue'
import Modal from '@/components/UI/Modal.vue'

export default defineComponent({
  components: {
    Modal
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {}
  }
})
