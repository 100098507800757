<template>
  <div
    class="bg-white min-w-96 max-h-screen font-normal text-center flex flex-col sidebar relative"
  >
    <div class="flex justify-center pt-16">
      <img src="@/assets/images/ghl-dark-logo.svg" alt="ghl-logo" />
    </div>
    <div class="flex flex-col pt-16 lg:text-sm xl:text-base">
      <div class="flex justify-center items-center text-2xl font-normal mb-8">
        <p v-if="currentRoute == 'conversationsDemoPage'">Try Conversations</p>
        <p v-else>Set up your first client</p>
      </div>
    </div>
    <div class="flex justify-center">
      <img
        v-if="currentRoute == 'conversationsDemoPage'"
        src="@/assets/images/conversation-people.svg"
        alt="account-logo"
      />
      <img v-else src="@/assets/images/account-logo.svg" alt="account-logo" />
    </div>
    <div
      v-if="currentRoute == 'conversationsDemoPage'"
      class="justify-self-end absolute bottom-0 items-center bg-blue-50 bg-opacity-80 w-full  h-32"
    >
      <progress-bar class="mt-10 mx-20 relative" />
      <tool-tip
        :title="tooltipTitle"
        :discription="tooltipDiscription"
        v-show="showTooltip"
      />
      <div
        class="flex flex-row justify-center item-center w-44 h-7 rounded mx-20 relative mt-3"
      >
        <p
          @click="goToDashboard"
          class="flex flex-row font-medium text-sm mt-1.5 text-blue-600 cursor-pointer"
        >
          Go to Dashboard
        </p>
        <img
          class="ml-2.5 mt-1 flex flex-row"
          src="@/assets/images/blue-right-arrow.svg"
          alt="b-right-arrow"
        />
      </div>
    </div>
    <div v-else class="flex justify-center lg:text-sm xl:text-base">
      <div
        class="justify-self-end absolute bottom-14 items-center text-xs font-normal mb-8"
      >
        <div
          v-show="currentRoute == 'businessAddAccountMapPage'"
          class="text-blue-600 cursor-pointer"
          @click="addDefaultLocation"
        >
          I am starting out my business
        </div>
        <div
          v-show="currentRoute == 'businessAddAccountPage'"
          class="text-gray-600"
        >
          Let me search & pick my client
        </div>
      </div>
      <div class="justify-self-end absolute bottom-8 sm:mt-6">
        <button
          v-show="currentRoute == 'businessAddAccountMapPage'"
          class="green-filled-button green-btn flex justify-center items-center disabled:cursor-default rounded-lg w-56"
          @click="chooseManualLocation"
        >
          <span class="mr-1">
            <img src="@/assets/images/white-plus-sign.svg" alt="plus-sign" />
          </span>
          <span class="mr-1 text-xs font-normal">Add account manually</span>
        </button>
        <button
          v-show="currentRoute == 'businessAddAccountPage'"
          class="green-filled-button gray-btn flex justify-center items-center disabled:cursor-default rounded-lg w-56"
          @click="chooseLocation"
          :class="{ 'pointer-events-none': disableAction }"
          :disabled="disableAction"
        >
          <span class="mr-2">
            <img src="@/assets/images/map-marker.svg" alt="plus-sign" />
          </span>
          <span class="mr-1 text-xs font-normal">Go to Maps</span>
        </button>
      </div>
    </div>
    <overlay
      :class="{ 'w-full': enableOverlay }"
      :showOverlay="enableOverlay"
      id="sidebaroverlay"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ToolTip from '@/components/UI/Tooltip.vue'
import ProgressBar from './UI/ProgressBar.vue'
import Overlay from './UI/Overlay.vue'

export default defineComponent({
  components: {
    ToolTip,
    ProgressBar,
    Overlay
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const currentRoute = router.currentRoute.value.name
    const tooltipTitle = ref('Access Dashboard from here')
    const tooltipDiscription = ref(
      'After you experience conversations you can always click the "Go to Dashboard" anytime.'
    )
    const showTooltip = computed(() => store.state.location.tooltip)

    const enableDashboardLink = computed(() => store.state.location.demoEnd)

    const enableOverlay = computed(() => store.state.location.overlay)

    const chooseManualLocation = () => {
      router.push({
        name: 'businessAddAccountPage'
      })
    }

    const chooseLocation = () => {
      router.push({
        name: 'businessAddAccountMapPage'
      })
    }

    const disableAction = computed(() => {
      return store.state.location.disableAction
    })

    const goToDashboard = () => {
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('onboarding-dashboard')
      })
    }

    const addDefaultLocation = () => {
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('default-location')
      })
    }

    return {
      chooseManualLocation,
      chooseLocation,
      currentRoute,
      goToDashboard,
      tooltipTitle,
      tooltipDiscription,
      showTooltip,
      enableDashboardLink,
      disableAction,
      addDefaultLocation,
      enableOverlay
    }
  }
})
</script>

<style scoped>
.sidebar {
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.11);
  min-width: 340px;
}
@media (max-width: 1024px) {
  .sidebar {
    display: none;
  }
}
</style>
