interface Config {
  baseUrl: string
  locationApi: string
  backendUrl: string
}

const config: { [key: string]: Config } = {
  development: {
    baseUrl: 'http://localhost:8080/',
    locationApi:
      'https://public-api-dot-highlevel-staging.uc.r.appspot.com/v1/locations/',
    backendUrl: 'http://localhost:5000'
  },
  staging: {
    baseUrl: 'https://mayur-ghl.surge.sh/',
    locationApi:
      'https://public-api-dot-highlevel-staging.uc.r.appspot.com/v1/locations/',
    backendUrl: 'https://mayur-dot-highlevel-staging.uc.r.appspot.com'
  },
  beta: {
    baseUrl: 'https://beta.gohighlevel.com/',
    locationApi: 'https://rest.gohighlevel.com/v1/locations/',
    backendUrl: 'https://mayur-dot-highlevel-staging.uc.r.appspot.com'
  },
  production: {
    baseUrl: 'https://app.gohighlevel.com/',
    locationApi: 'https://rest.gohighlevel.com/v1/locations/',
    backendUrl: 'https://mayur-dot-highlevel-staging.uc.r.appspot.com'
  }
}

export default config[process.env.NODE_ENV]

export function useConfig(env: string) {
  return config[process.env.NODE_ENV]
}
