
import { defineComponent } from 'vue'
import AddAccountSideBar from '@/components/AddAccountSideBar.vue'
import BusinessAddAccountMap from '@/components/BusinessAddAccountMap.vue'

export default defineComponent({
  components: {
    AddAccountSideBar,
    BusinessAddAccountMap
  },
  setup() {
    return {}
  }
})
