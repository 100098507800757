<template>
  <div
    class="absolute max-w-xs w-4/5 flex flex-col justify-items-start items-start rounded shadow-md bg-white -top-10 left-10 py-2 px-3"
  >
    <div class="w-0 h-0 absolute arrow-center-bottom -bottom-3.5 left-28"></div>
    <p class="font-medium text-sm text-left">{{ title }}</p>
    <p class="font-normal text-sm text-left my-2">
      {{ discription }}
    </p>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    title: String,
    discription: String
  },
  setup(props) {
    return {}
  }
})
</script>
<style>
.arrow-center-bottom {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 30px solid #ffffff;
}
</style>
