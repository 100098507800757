
import {
  defineComponent,
  ref,
  onMounted,
  watch,
  onBeforeUnmount,
  onUnmounted
} from 'vue'
import { useStore } from 'vuex'
import { useConfig } from '../config'
import Postmate from 'postmate'
import { onBeforeRouteUpdate } from 'vue-router'
import Modal from '@/components/UI/Modal.vue'
import Spinner from '@/components/UI/Spinner.vue'
import axios from 'axios'

export default defineComponent({
  components: {
    Modal,
    Spinner
  },
  setup() {
    const store = useStore()
    const config = useConfig(process.env.NODE_ENV)
    const handshake = ref(null)
    const spmConversation = ref(null)
    const showModal = ref(false)
    const showDemoSpinner = ref(false)
    const skipDemoSpinner = ref(false)

    const handshakeIframe = () => {
      handshake.value = new Postmate({
        container: spmConversation.value as HTMLElement,
        url: `${config.baseUrl}location/${store.state.location.id}/conversations?onboarding=true`,
        name: 'spm-conversation',
        classListArray: ['conversation-iframe', 'w-full', 'min-h-screen'],
        model: {}
      })
      handshake.value.then((child: any) => {
        child.on('onboarding-tooltip-end', () => {
          store.commit('location/showTooltip', true)
          store.commit('location/updateDemoEnd', true)
          setTimeout(() => {
            store.commit('location/showTooltip', false)
          }, 2000)
        })
        child.on('onboarding-dashboard', () => {
          store?.state?.iframe?.handshake?.then(parent => {
            parent.emit('onboarding-dashboard')
          })
        })
        child.on('onboarding-redirect-end', () => {
          store.commit('location/showOverlay', false)
          store.commit('location/updateDemoEnd', true)
          store?.state?.iframe?.handshake?.then(parent => {
            parent.emit('onboarding-complete')
          })
        })
        child.on('overlay-show', () => {
          store.commit('location/showOverlay', true)
          showModal.value = true
        })
        child.on('tour-started', () => {
          showDemoSpinner.value = false
          showModal.value = false
        })
      })
    }

    const startDemo = () => {
      handshake.value.then((child: any) => {
        child.call('startDemo')
      })
    }
    const skipDemo = () => {
      skipDemoSpinner.value = true
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('onboarding-dashboard')
      })
    }
    const sendMessage = async () => {
      showDemoSpinner.value = true
      startDemo()
    }

    onMounted(() => {
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('ga-event', 'onb-conv-demo')
        handshakeIframe()
      })
    })

    onUnmounted(() => {
      handshake?.then(child => {
        child.destroy()
      })
    })

    /* In-Component Guards */
    onBeforeRouteUpdate((to, from, next) => {
      if (to.path.includes('onboarding/account')) {
        next({ name: 'conversationsDemoPage' })
      } else {
        next()
      }
    })

    return {
      spmConversation,
      handshake,
      handshakeIframe,
      showModal,
      startDemo,
      skipDemo,
      showDemoSpinner,
      skipDemoSpinner,
      sendMessage
    }
  }
})
