<template>
  <modal :active="active" @closeModal="$emit('close-terms-and-conditions')">
    <div class="max-h-screen py-8 flex flex-col">
      <div class="bg-indigo-500 pl-6 pr-4 pt-2 pb-4 flex flex-col">
        <div
          class="bg-gray-200 w-6 h-6 flex items-center justify-center rounded-full self-end"
          @click="$emit('close-terms-and-conditions')"
        >
          <img
            class="cursor-pointer"
            src="@/assets/images/x.svg"
            alt="cancel-icon"
          />
        </div>
        <div>
          <img
            class="cursor-pointer"
            src="@/assets/images/highlevel-transparent-logo.svg"
            alt="cancel-icon"
          />
        </div>
        <div class="text-white font-medium text-2xl mt-6">
          Terms and conditions
        </div>
      </div>
      <div
        class="bg-gray-100 pl-6 pr-4 pt-6 pb-4 flex flex-col h-full overflow-y-scroll"
      >
        <div class="bg-green-200 self-start px-2 py-1 mb-4 text-sm">
          <span>Effective date: </span>
          <span>August 1, 2020</span>
        </div>
        <div class="text-xs">
          At HighLevel, Inc., a HighLevel corporation (hereinafter, “HighHevel,”
          “GoHighLevel,” ,”GHL”, “Company”, “us,” or “we”), we respect and
          protect the privacy of visitors to our website, www.gohighlevel.com,
          and the other websites under the gohighlevel.com domain (collectively,
          the “Sites”), and our customers who use our SaaS product, web design
          software, tools, and related services (together with the Sites, the
          “Service”).
        </div>
        <div v-for="x in [1, 2, 3, 4, 5, 6, 7, 8]" class="my-2" :key="x">
          <h6 class="font-medium">
            {{ x }}. What “Personal Information” means
          </h6>
          <div class="text-xs">
            At HighLevel, Inc., a HighLevel corporation (hereinafter,
            “HighHevel,” “GoHighLevel,” ,”GHL”, “Company”, “us,” or “we”), we
            respect and protect the privacy of visitors to our website,
            www.gohighlevel.com, and the other websites under the
            gohighlevel.com domain (collectively, the “Sites”), and our
            customers who use our SaaS product, web design software, tools, and
            related services (together with the Sites, the “Service”).
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Modal from '@/components/UI/Modal.vue'

export default defineComponent({
  components: {
    Modal
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
