<template>
  <div class="flex min-h-screen">
    <add-account-side-bar :activeIndex="1" />
    <conversations-demo />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AddAccountSideBar from '@/components/AddAccountSideBar.vue'
import ConversationsDemo from '@/components/ConversationsDemo.vue'

export default defineComponent({
  components: {
    AddAccountSideBar,
    ConversationsDemo
  },
  setup() {
    return {}
  }
})
</script>
