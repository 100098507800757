
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ToolTip from '@/components/UI/Tooltip.vue'
import ProgressBar from './UI/ProgressBar.vue'
import Overlay from './UI/Overlay.vue'

export default defineComponent({
  components: {
    ToolTip,
    ProgressBar,
    Overlay
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const currentRoute = router.currentRoute.value.name
    const tooltipTitle = ref('Access Dashboard from here')
    const tooltipDiscription = ref(
      'After you experience conversations you can always click the "Go to Dashboard" anytime.'
    )
    const showTooltip = computed(() => store.state.location.tooltip)

    const enableDashboardLink = computed(() => store.state.location.demoEnd)

    const enableOverlay = computed(() => store.state.location.overlay)

    const chooseManualLocation = () => {
      router.push({
        name: 'businessAddAccountPage'
      })
    }

    const chooseLocation = () => {
      router.push({
        name: 'businessAddAccountMapPage'
      })
    }

    const disableAction = computed(() => {
      return store.state.location.disableAction
    })

    const goToDashboard = () => {
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('onboarding-dashboard')
      })
    }

    const addDefaultLocation = () => {
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('default-location')
      })
    }

    return {
      chooseManualLocation,
      chooseLocation,
      currentRoute,
      goToDashboard,
      tooltipTitle,
      tooltipDiscription,
      showTooltip,
      enableDashboardLink,
      disableAction,
      addDefaultLocation,
      enableOverlay
    }
  }
})
