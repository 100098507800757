<template>
  <div class="form-parent-container relative">
    <div
      class="absolute left-5 top-5 flex justify-center items-center cursor-pointer"
      @click="$router.push({ name: 'businessToolsPage' })"
    >
      <img class="h-3 w-3" src="@/assets/images/back.svg" alt="back-button" />
      <span class="text-sm text-gray-600 ml-1 mt-1">Back to tools</span>
    </div>
    <div class="form-child-container">
      <div class="md:text-lg lg:text-xl xl:text-2xl font-medium text-center">
        Finally {{ $store.state.iframe.firstName }}, let’s setup your account
      </div>
      <!-- <div class="pt-4 sm:text-xs xl:text-sm text-gray-600 text-center">
        One last step, then the account is all yours!
      </div> -->
      <div v-if="passwordPending">
        <div class="pt-20 pb-3">
          <label id="listbox-label" class="label">
            Password
          </label>
          <div class="relative w-full text-gray-400 focus-within:text-gray-600">
            <div
              class="absolute inset-y-0 right-0 flex items-center cursor-pointer mx-2"
              @click="updatePasswordType"
              :class="passwordType === 'text' ? ['opacity-30'] : ''"
            >
              <!-- Heroicon name: search -->
              <img src="@/assets/images/eye.svg" alt="search-logo" />
            </div>
            <input
              class="block w-full h-full pr-9 py-3 border-transparent text-gray-900 placeholder-gray-400 hover:outline-none hover:ring-indigo-500 focus:border-indigo-500 focus:border-transparent sm:text-sm border-gray-300"
              autocomplete="off"
              :type="passwordType"
              name="search"
              v-model="password"
            />
          </div>
        </div>

        <div
          class="flex items-center py-1"
          v-for="(value, errorPropertyName) in passwordErrors"
          :key="errorPropertyName"
        >
          <img
            class="w-4 h-4"
            v-if="value"
            src="@/assets/images/step-completed.svg"
            alt="completed-step"
          />

          <img
            class="w-4 h-4"
            v-else
            src="@/assets/images/circle.svg"
            alt="circle"
          />

          <div
            class="ml-3 text-sm"
            :class="value ? ['text-gray-500'] : ['text-gray-400']"
          >
            {{ errorPropertyName }}
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-center items-center pt-10">
        <input
          class="mr-4"
          type="checkbox"
          name="termsnCondition"
          v-model="termsnCondition"
        />
        <label
          >I accept HighLevel's
          <a
            class="text-blue-500 font-medium cursor-pointer"
            href="https://www.gohighlevel.com/legal"
            target="_blank"
            >Terms of Service</a
          ></label
        >
      </div>
      <div class="pt-4 sm:mt-4 flex justify-center">
        <button
          v-if="passwordPending"
          :disabled="
            Object.values(passwordErrors)?.includes(false) ||
              showLoader ||
              !termsnCondition
          "
          class="base-filled-button indigo-btn flex justify-center items-center disabled:cursor-default"
          @click="submitData"
        >
          <spinner v-if="showLoader"></spinner>
          <span v-else class="mr-1">Setup my account</span>
        </button>
        <button
          v-else
          :disabled="showLoader || !termsnCondition"
          class="base-filled-button indigo-btn flex justify-center items-center disabled:cursor-default"
          @click="submitData"
        >
          <spinner v-if="showLoader"></spinner>
          <span v-else class="mr-1">Setup my account</span>
        </button>
      </div>
      <!-- Term and condition code -->
      <!-- <div class="flex justify-center">
        <div class="text-xs text-gray-400 justify-self-end absolute bottom-5">
          By clicking on “Setup my account”, you agree to our
          <span
            @click.prevent.stop="showTermsAndConditions = true"
            class="text-black font-medium cursor-pointer"
            >Terms and Conditions.</span
          >
        </div>
      </div> -->
    </div>
    <term-and-condition
      :active="showTermsAndConditions"
      @closeTermsAndConditions="showTermsAndConditions = false"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import Spinner from '@/components/UI/Spinner.vue'
import TermAndCondition from '@/components/TermAndCondition.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Spinner,
    TermAndCondition
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const password = ref()
    const showTermsAndConditions = ref()
    const showLoader = ref(false)
    const passwordType = ref('password')
    const passwordErrors = ref({
      'At least 8 characters long': false,
      'One lowercase character': false,
      'One uppercase character': false,
      'One number and one special character': false
    })
    const termsnCondition = ref(store.state.business.tandc)
    const passwordPending = ref(store?.state?.iframe?.isPasswordPending)
    watch(password, newPassword => {
      if (newPassword.length >= 8)
        passwordErrors.value['At least 8 characters long'] = true
      else passwordErrors.value['At least 8 characters long'] = false
      if (newPassword.match(/^(?=.*[A-Z])/))
        passwordErrors.value['One uppercase character'] = true
      else passwordErrors.value['One uppercase character'] = false
      if (newPassword.match(/^(?=.*[a-z])/))
        passwordErrors.value['One lowercase character'] = true
      else passwordErrors.value['One lowercase character'] = false
      if (newPassword.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])/))
        passwordErrors.value['One number and one special character'] = true
      else passwordErrors.value['One number and one special character'] = false
    })
    const updatePasswordType = () => {
      if (passwordType.value === 'password') passwordType.value = 'text'
      else passwordType.value = 'password'
    }
    const submitData = () => {
      showLoader.value = true
      if (passwordPending.value)
        store.commit('business/updatePassword', password.value)
      store.commit('business/updateTermAndCondition', termsnCondition.value)
      // @ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('onboarding-data', JSON.stringify(store.state.business))
      })
      // @ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('ga-event', 'onb-pwd-setup')
      })
    }
    return {
      showTermsAndConditions,
      showLoader,
      password,
      passwordType,
      passwordErrors,
      updatePasswordType,
      submitData,
      termsnCondition,
      passwordPending
    }
  }
})
</script>

<style scoped></style>