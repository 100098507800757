
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    title: String,
    discription: String
  },
  setup(props) {
    return {}
  }
})
