<template>
  <div class="parent-container relative">
    <div class="child-container">
      <div class="pt-20 flex flex-col justify-items-start">
        <div
          class="flex flex-row  mb-8 cursor-pointer"
          :class="{ 'pointer-events-none': disableAction }"
          @click="chooseLocation"
        >
          <img
            src="@/assets/images/left-arrow.svg"
            alt="left-arrow"
            class="mx-2 w-2"
          />
          <span class="ml-1 text-gray-400 font-medium text-sm">
            Back to Google Maps
          </span>
        </div>
        <div class="relative w-full flex flex-col justify-items-start bg-white">
          <span class="mt-10 mb-8 ml-10 font-normal text-base">
            Sub-Account Information
          </span>
          <div class="ml-10">
            <label id="listbox-label" class="label text-sm font-medium">
              Please enter the business name
            </label>
            <div
              class="relative w-11/12 text-gray-400 focus-within:text-gray-600 mb-10"
            >
              <input
                class="border border-gray-300 font-normal rounded-sm block w-full h-full pl-4 py-3  text-gray-900 placeholder-gray-400 hover:outline-none hover:ring-indigo-500 focus:border-indigo-500 focus:border-transparent sm:text-sm"
                autocomplete="off"
                name="business-name"
                v-model="name"
              />
            </div>
          </div>
          <div class="flex flex-col ml-7">
            <button
              class="base-filled-button indigo-btn disabled:cursor-default w-80 mb-10"
              @click.prevent.stop="submitBusinessName"
              :disabled="showLoader || name.length == 0"
            >
              <spinner v-if="showLoader" />
              <span v-else class="mr-1 flex flex-row font-bold text-sm">
                Show me how conversations work
                <img
                  src="@/assets/images/minus.svg"
                  alt="right-arrow-white"
                  class="ml-2 w-2"
                />
                <img
                  src="@/assets/images/right-arrow-white.svg"
                  alt="left-arrow"
                  class="w-2"
                />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { onBeforeRouteUpdate, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Spinner from '@/components/UI/Spinner.vue'
import axios from 'axios'
import { useConfig } from '../config'

export default defineComponent({
  components: {
    Spinner
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const config = useConfig(process.env.NODE_ENV)
    const name = ref(store.state.location.name)
    const showLoader = ref(false)

    const chooseLocation = () => {
      router.push({
        name: 'businessAddAccountMapPage'
      })
    }
    const submitBusinessName = async () => {
      showLoader.value = true
      store.commit('location/disableAction', true)
      const body = {
        businessName: name.value,
        address: store.state.location.address,
        city: store.state.location.city,
        state: store.state.location.state,
        country: store.state.location.country,
        postalCode: store.state.postalCode,
        website: store.state.location.website,
        phone: store.state.location.phone,
        isContactCreate: true,
        social: {
          googlePlaceId: store.state.location.googlePlacesId
        }
      }
      const options = {
        headers: {
          Authorization: `Bearer ${store.state.iframe.authKey}`,
          'Content-Type': 'application/json',
          'internal-channel': 'web'
        }
      }
      try {
        const location = await axios.post(config.locationApi, body, options)
        showLoader.value = false
        if (location.status == 200) {
          store.commit('location/updateLocationId', location.data.id)
          store.commit('location/disableAction', false)
          const payload = {
            locationId: store.state.location.id
          }
          store?.state?.iframe?.handshake?.then(parent => {
            parent.emit('ga-event', 'onb-enter-sub-acct-info')
            parent.emit('onboarding-location', JSON.stringify(payload))
          })
          router.push({
            name: 'conversationsDemoPage'
          })
        } else {
          store?.state?.iframe?.handshake?.then(parent => {
            parent.emit('onboarding-dashboard')
          })
        }
      } catch (err) {
        showLoader.value = false
        store?.state?.iframe?.handshake?.then(parent => {
          parent.emit('onboarding-dashboard')
        })
      }
    }

    const disableAction = computed(() => {
      return store.state.location.disableAction
    })

    /* In-Component Guards */
    onBeforeRouteUpdate((to, from, next) => {
      const interval = setInterval(() => {
        if (!showLoader.value) {
          clearInterval(interval)
          if (
            to.path.includes('onboarding/search') &&
            store.state.location.id != ''
          ) {
            next({ name: 'conversationsDemoPage' })
          } else {
            next()
          }
        }
      }, 400)
    })

    return {
      chooseLocation,
      submitBusinessName,
      name,
      showLoader,
      disableAction
    }
  }
})
</script>
