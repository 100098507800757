
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import lodash from 'lodash'
import firebase from 'firebase'
import libphonenumber from 'google-libphonenumber'

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

declare const google: any
const mapOptions = {
  zoom: 12,
  disableDefaultUI: true,
  zoomControl: true,
  center: new google.maps.LatLng(40.67, -73.94), // New York
  styles: [
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#f4f4f4'
        }
      ]
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on'
        },
        {
          color: '#e4e4e4'
        }
      ]
    },
    {
      featureType: 'landscape.natural.landcover',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'landscape.natural.landcover',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'landscape.natural.terrain',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'off'
        },
        {
          hue: '#ff0000'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'poi.attraction',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.business',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.government',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.medical',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified'
        },
        {
          gamma: '1'
        },
        {
          weight: '0'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#e4e4e4'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.place_of_worship',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.school',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [
        {
          saturation: -100
        },
        {
          lightness: 99
        },
        {
          visibility: 'on'
        },
        {
          color: '#ffffff'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#808080'
        },
        {
          lightness: 54
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#a0a0a0'
        },
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#ffffff'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'labels.text',
      stylers: [
        {
          color: '#ff0000'
        },
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'labels.text.fill',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'transit.station',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on'
        }
      ]
    },
    {
      featureType: 'transit.station',
      elementType: 'labels.text',
      stylers: [
        {
          color: '#ff0000'
        },
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          saturation: 43
        },
        {
          lightness: -11
        },
        {
          hue: '#0088ff'
        }
      ]
    }
  ]
}

export default defineComponent({
  setup() {
    const router = useRouter()
    const autocomplete = ref(null)
    const store = useStore()
    let map
    const places = ref([])
    const markers = ref([])
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        const initialLocation = new google.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        )
        mapOptions.center = initialLocation
        if (map) {
          map.setCenter(initialLocation)
        }
      })
    }

    const init = () => {
      const mapElement = document.getElementById('location-map')
      const searchBox = new google.maps.places.SearchBox(autocomplete.value)
      map = new google.maps.Map(mapElement, mapOptions)

      searchBox.addListener('places_changed', () => {
        const place = searchBox.getPlaces()
        places.value = place.slice(0, 10)

        // Clear out the old markers.
        markers.value.forEach(function(marker) {
          marker.setMap(null)
        })
        markers.value = []
        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds()
        places.value.forEach(function(place) {
          if (!place.geometry) {
            return
          }

          const marker = new google.maps.Marker({
            position: place.geometry.location,
            map: map,
            label: {
              fontFamily: 'Magicons',
              text: '\uE96F',
              color: '#ffff'
            }
          })

          markers.value.push(marker)

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport)
          } else {
            bounds.extend(place.geometry.location)
          }
          store?.state?.iframe?.handshake?.then(parent => {
            parent.emit('ga-event', 'onb-maps-search')
          })
        })

        map.fitBounds(bounds)
      })

      map.addListener('bounds_changed', function() {
        searchBox.setBounds(map.getBounds())
      })
    }

    const getListingClass = index => {
      return ['bg-blue-600', 'bg-green-600', 'bg-yellow-600', 'bg-pink-600'][
        index % 4
      ]
    }

    const chooseLocation = place => {
      let streetNumber, streetName, city, state, country, postalCode, phone
      lodash.each(place.address_components, function(component) {
        if (component.types.indexOf('street_number') > -1) {
          streetNumber = component.long_name
        }
        if (component.types.indexOf('route') > -1) {
          streetName = component.long_name
        }
        if (component.types.indexOf('locality') > -1) {
          city = component.long_name
        }
        if (component.types.indexOf('administrative_area_level_1') > -1) {
          state = component.short_name
        }
        if (component.types.indexOf('country') > -1) {
          country = component.short_name
        }
        if (component.types.indexOf('postal_code') > -1) {
          postalCode = component.long_name
        }
      })

      try {
        phone = phoneUtil.format(
          phoneUtil.parse(place.international_phone_number),
          libphonenumber.PhoneNumberFormat.E164
        )
      } catch (error) {
        // console.error("Can't format phone:", error)
        phone = ''
      }

      const addressGeo = new firebase.firestore.GeoPoint(
        place.geometry.location.lat(),
        place.geometry.location.lng()
      )

      const address =
        (streetNumber || '') +
        (streetNumber && streetName ? ' ' : '') +
        (streetName || '')

      store.commit('location/updateLocation', {
        googlePlacesId: place.place_id,
        name: place.name,
        address: address,
        city: city,
        _state: state,
        country: country,
        postalCode: postalCode,
        categories: '',
        addressGeo: addressGeo,
        phone: phone,
        website: place.website
      })

      router.push({
        name: 'businessAddAccountPage'
      })
    }

    onMounted(() => {
      init()
    })

    return {
      init,
      autocomplete,
      map,
      places,
      markers,
      getListingClass,
      chooseLocation
    }
  }
})
