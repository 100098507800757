
import { computed, defineComponent, ref } from 'vue'
import { onBeforeRouteUpdate, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Spinner from '@/components/UI/Spinner.vue'
import axios from 'axios'
import { useConfig } from '../config'

export default defineComponent({
  components: {
    Spinner
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const config = useConfig(process.env.NODE_ENV)
    const name = ref(store.state.location.name)
    const showLoader = ref(false)

    const chooseLocation = () => {
      router.push({
        name: 'businessAddAccountMapPage'
      })
    }
    const submitBusinessName = async () => {
      showLoader.value = true
      store.commit('location/disableAction', true)
      const body = {
        businessName: name.value,
        address: store.state.location.address,
        city: store.state.location.city,
        state: store.state.location.state,
        country: store.state.location.country,
        postalCode: store.state.postalCode,
        website: store.state.location.website,
        phone: store.state.location.phone,
        isContactCreate: true,
        social: {
          googlePlaceId: store.state.location.googlePlacesId
        }
      }
      const options = {
        headers: {
          Authorization: `Bearer ${store.state.iframe.authKey}`,
          'Content-Type': 'application/json',
          'internal-channel': 'web'
        }
      }
      try {
        const location = await axios.post(config.locationApi, body, options)
        showLoader.value = false
        if (location.status == 200) {
          store.commit('location/updateLocationId', location.data.id)
          store.commit('location/disableAction', false)
          const payload = {
            locationId: store.state.location.id
          }
          store?.state?.iframe?.handshake?.then(parent => {
            parent.emit('ga-event', 'onb-enter-sub-acct-info')
            parent.emit('onboarding-location', JSON.stringify(payload))
          })
          router.push({
            name: 'conversationsDemoPage'
          })
        } else {
          store?.state?.iframe?.handshake?.then(parent => {
            parent.emit('onboarding-dashboard')
          })
        }
      } catch (err) {
        showLoader.value = false
        store?.state?.iframe?.handshake?.then(parent => {
          parent.emit('onboarding-dashboard')
        })
      }
    }

    const disableAction = computed(() => {
      return store.state.location.disableAction
    })

    /* In-Component Guards */
    onBeforeRouteUpdate((to, from, next) => {
      const interval = setInterval(() => {
        if (!showLoader.value) {
          clearInterval(interval)
          if (
            to.path.includes('onboarding/search') &&
            store.state.location.id != ''
          ) {
            next({ name: 'conversationsDemoPage' })
          } else {
            next()
          }
        }
      }, 400)
    })

    return {
      chooseLocation,
      submitBusinessName,
      name,
      showLoader,
      disableAction
    }
  }
})
