
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import Spinner from '@/components/UI/Spinner.vue'
import TermAndCondition from '@/components/TermAndCondition.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    Spinner,
    TermAndCondition
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const password = ref()
    const showTermsAndConditions = ref()
    const showLoader = ref(false)
    const passwordType = ref('password')
    const passwordErrors = ref({
      'At least 8 characters long': false,
      'One lowercase character': false,
      'One uppercase character': false,
      'One number and one special character': false
    })
    const termsnCondition = ref(store.state.business.tandc)
    const passwordPending = ref(store?.state?.iframe?.isPasswordPending)
    watch(password, newPassword => {
      if (newPassword.length >= 8)
        passwordErrors.value['At least 8 characters long'] = true
      else passwordErrors.value['At least 8 characters long'] = false
      if (newPassword.match(/^(?=.*[A-Z])/))
        passwordErrors.value['One uppercase character'] = true
      else passwordErrors.value['One uppercase character'] = false
      if (newPassword.match(/^(?=.*[a-z])/))
        passwordErrors.value['One lowercase character'] = true
      else passwordErrors.value['One lowercase character'] = false
      if (newPassword.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])/))
        passwordErrors.value['One number and one special character'] = true
      else passwordErrors.value['One number and one special character'] = false
    })
    const updatePasswordType = () => {
      if (passwordType.value === 'password') passwordType.value = 'text'
      else passwordType.value = 'password'
    }
    const submitData = () => {
      showLoader.value = true
      if (passwordPending.value)
        store.commit('business/updatePassword', password.value)
      store.commit('business/updateTermAndCondition', termsnCondition.value)
      // @ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('onboarding-data', JSON.stringify(store.state.business))
      })
      // @ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('ga-event', 'onb-pwd-setup')
      })
    }
    return {
      showTermsAndConditions,
      showLoader,
      password,
      passwordType,
      passwordErrors,
      updatePasswordType,
      submitData,
      termsnCondition,
      passwordPending
    }
  }
})
