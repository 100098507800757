<template>
  <div
    class="absolute flex flex-col z-10"
    :class="{ overlay: showOverlay, 'h-full': showOverlay }"
    :id="id"
  >
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: ['showOverlay', 'id'],
  setup() {
    return {}
  }
})
</script>
<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
